.zoombox-overlay {
  display: none;

  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 9999;

  & > .zoombox-close-btn {
    position: absolute;
    right: 15px;
    top: 15px;
    width: 30px;
    height: 30px;

    text-align: center;
    line-height: 30px;
    font-size: 30px;
    cursor: pointer;
    z-index: 10000;

    color: #333;
  }

  & > .zoombox-inner {
    overflow: hidden;
    margin: 15px;
    height: calc(100% - 30px);
    position: static;

    @media (min-width: 768px) {
      margin: 25px 50px;
      height: calc(100% - 50px);
    }

    & > .zoombox-image {
      max-width: 100%;
      height: auto;
    }

    .slick-slide {
      float: left;
      outline: none !important;
      margin: 5px;

      .zoombox-image {
        display: block;
        margin: 0 auto;

        max-width: calc(100vw - 50px);
        max-height: calc(100vh - 50px);

        @media (min-width: 768px) {
          max-width: calc(100vw - 150px);
          max-height: calc(100vh - 70px);
        }
      }
    }

    .slick-arrow {
      position: absolute;
      top: calc(50% - 25px);
      height: 50px;
      width: 40px;
      padding: 0;
      z-index: 1;
      background: none;
      border: none;
      outline: none;
      cursor: pointer;

      &.slick-prev {
        left: 0;
      }

      &.slick-next {
        right: 0;
      }

      & > svg {
        max-height: 100%;
        width: auto;
      }
    }
  }
}

.zoombox {
  cursor: pointer;
}
